<template>
  <section id="dashboard-ecommerce">

    <b-row class="match-height">
      <!--/ Company Table Card -->
      <b-col lg="12">
        <b-card>
          <b-card-body>
            <b-card-title>Vos Noeuds</b-card-title>
            <b-row align-h="between">
              <b-col md="4" sm="8">
              </b-col>
              <b-col md="3" class="align-right">
                <b-form @submit="logSearch">
                  <b-form-group>
                    <b-input-group size="sm">
                      <b-form-input
                        id="filterInput"
                        v-model="logSearchInput"
                        type="search"
                        placeholder="Rechercher un # Block"
                      />
                      <b-input-group-append>
                        <b-button type="submit">Rechercher</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-form>
              </b-col>
            </b-row>

            <b-table :fields="fields" :items="logs" responsive class="mb-0">
              <!-- A custom formatted column -->
              <template #cell(_signature)="data">
                <span class="text-nowrap">{{
                  data.value.substring(0, 28) + "..."
                }}</span>
              </template>

              <template #cell(status)="data">
                <b-badge pill :variant="data.value.variant">
                  {{ data.value.status }}
                </b-badge>
              </template>

              <template #cell(_owner_or_delegate)="data">
                <b-badge pill :variant="data.value.variant">
                  {{ data.value.status }}
                </b-badge>
              </template>
              <template #cell()="data">
                {{ data.value }}
              </template>

              <template #cell(id)="data">
                <a :href="`/log/${data.item.id}`">
                  <feather-icon icon="LinkIcon" size="24" />
                </a>
                {{ data.value }}
              </template>

              <template #cell(details)="row">
                <b-button @click="row.toggleDetails" variant="flat-secondary">
                  {{ row._showDetails ? "Détails" : "Détails" }}
                </b-button>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col
                      v-for="(key, idx) in Object.keys(row.item).filter(x => !hideFields.includes(x))"
                      :key="idx"
                      md="4"
                      class="mb-1"
                    >
                      <strong>{{ key }} : </strong>{{ row.item[key] }}
                      <b-button
                        v-if="key === '_certificate'"
                        @click.prevent="downloadCertificate(row.item._id)"
                        variant="primary"
                      >
                        Télécharger votre attestation
                      </b-button>
                      <b-button
                          v-if="key === '_qrcode'"
                          @click.prevent="downloadQRCode(row.item._id)"
                          variant="primary"
                      >
                        Télécharger votre QRCode
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-button
                    size="sm"
                    variant="outline-secondary"
                    @click="row.toggleDetails"
                  >
                    Masquer les détails
                  </b-button>
                </b-card>
              </template>
            </b-table>
            <b-row align-h="between">
              <b-col md="4" sm="8">
              </b-col>
              <b-col md="0" class="align-right">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalLogsNb"
                    :per-page="perPage"
                    @change="onChangePage"
                    aria-controls="my-table"
                    class="mt-2"
                ></b-pagination>
              </b-col>
            </b-row>


          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BTable,
  BBadge,
  BCardHeader,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox, BPagination,
} from "bootstrap-vue";
import axios from "axios";

import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  components: {
    BPagination,
    BRow,
    BCol,
    BTable,
    BBadge,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      privateKey: "",
      pubKey: "",
      totalLogsNb: 0,
      peersConnected: 0,
      outOfSyncInterval: 0,
      logSearchInput: "",
      realtimeLogsUpdate: true,
      lastBlockId: null,
      // Champs masqués dans les détails d'un log
      hideFields: ['status', '_showDetails', 'id', '_owner_or_delegate'],
      currentPage: 1,
      perPage: 10,
      apexChartData: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: false,
            curve: "straight",
          },
          legend: {
            show: true,
            position: "top",
            horizontalAlign: "left",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [],
          },
          fill: {
            opacity: 1,
            type: "solid",
          },
          tooltip: {
            shared: false,
          },
          colors: ["#2bdac7"],
        },
      },
      // rangePicker: ["2019-05-01", "2019-05-10"],

      nodeState: [
        {
          title: "Version",
          avatar: "HexagonIcon",
          avatarVariant: "light-success",
          value: "3.2024",
        },
        {
          title: "Connexion au réseau",
          avatar: "WifiIcon",
          avatarVariant: "light-danger",
          value: "Chargement...",
        },
        {
          title: "Dernière Synchronisation",
          avatar: "ClockIcon",
          avatarVariant: "light-danger",
          value: "Chargement...",
        },
      ],

      fields: [
        // A column that needs custom formatting
        { key: "_id", label: "# Block" },
        { key: "_timestamp", label: "Horodatage" },
        { key: "_signature", label: "Signature" },
        { key: "status", label: "Statut" },
        { key: "_owner_or_delegate", label: "Type signature" },
        { key: "details", label: "Détails" },
      ],
      logs: [],
    };
  },
  watch: {
    realtimeLogsUpdate: function () {
      if (this.realtimeLogsUpdate) {
        this.logs = [];
        this.lastBlockId = null;
        this.logSearchInput = "";
        this.updateLastLogs();
      }
    },
  },
  mounted() {
    this.privateKey = localStorage.getItem('privateKey') ;
    this.publicKey = localStorage.getItem('publicKey') ;
    if (this.$route.params.log) {
      const id = Number(this.$route.params.log);
      this.logSearchInput = id;
      this.fetchLogById(id);
    }
    this.getAllLogFromUser();

  },
  computed: {
    hideDownloadCertificateBtn() {
      return (window.location.href.indexOf("openlogs.fr") > -1);
    }
  },
  methods: {
    downloadCertificate(id) {
      axios.get(`https://apisb.openlogs.fr/api/logs/${id}/certificate`, {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        }
      }).then(({ data }) => {
        const a = document.createElement("a");
        a.href = "data:application/octet-stream;base64,"+data.data;
        a.download = `attestation-openlogs.pdf`;
        a.click();
      });
    },
    downloadQRCode(id) {
      axios.get(`https://apisb.openlogs.fr/api/logs/${id}/qrcode`, {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        }
      }).then(({ data }) => {
        const a = document.createElement("a");
        a.href = "data:application/octet-stream;base64,"+data.data;
        a.download = `openlogs-qrCode.png`;
        a.click();
      });
    },
    fetchLogById(id) {

      axios
        .get(`https://apisb.openlogs.fr/api/logs?id=${id}&signature=1`)
        .then((res) => {
          if (res.data.success && res.data.data.length > 0) {
            for (const log of res.data.data) {
              log._showDetails = true;
              log._timestamp = new Date(log._timestamp).toUTCString();
              
              log._owner_or_delegate = log._owner == log._author
                ? { status: "Propriétaire", variant: "success" }
                : { status: "Déléguée", variant: "info" };
              
              log.status = log._signature
                ? { status: "Vérifié", variant: "success" }
                : { status: "N/C", variant: "danger" };
            }
            this.logs = res.data.data; // logs
          } else {
            alert(`Aucun résultat pour #${this.logSearchInput}`);
          }
        })
        .catch((err) => {
          console.error(err);

        });
    },
    logSearch(e) {
      e.preventDefault();

      this.logSearchInput = Number(this.logSearchInput);
      this.fetchLogById(this.logSearchInput);
    },
    onChangePage() {
      this.$nextTick(() => {
        this.getLogsInformationPerPage();
      });
    },
    getLogsInformationPerPage() {
      axios.get("https://apisb.openlogs.fr/api/user/logs", {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        },
        params: {
          limit: this.perPage,
          offset: this.currentPage - 1,
        }
      }).then((data) => {
        for (const log of data.data.data) {
          log._showDetails = false;
          log._timestamp = new Date(log._timestamp).toUTCString();

          log._owner_or_delegate = log._owner == log._author
              ? { status: "Propriétaire", variant: "success" }
              : { status: "Déléguée", variant: "info" };

          log.status = log._signature
              ? { status: "Vérifié", variant: "success" }
              : { status: "N/C", variant: "danger" };
        }
        this.logs = data.data.data;
      })
    },
    getAllLogFromUser() {
      axios.get("https://apisb.openlogs.fr/api/user/logs", {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        }
      }).then((data) => {
        console.log(data.data.data)
        for (const log of data.data.data) {
          log._showDetails = false;
          log._timestamp = new Date(log._timestamp).toUTCString();

          log._owner_or_delegate = log._owner == log._author
              ? { status: "Propriétaire", variant: "success" }
              : { status: "Déléguée", variant: "info" };

          log.status = log._signature
              ? { status: "Vérifié", variant: "success" }
              : { status: "N/C", variant: "danger" };
        }
        this.logs = data.data.data;
        this.totalLogsNb = data.data.maxSize;
      })
    },

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>

<style lang="scss" scoped>
.transaction-item .media {
  align-items: center;
}
</style>
